import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import theme from '../../../theme/theme'
import {media} from '../../../utils'
import Typography from '../../atoms/Typography'
import ScrollToTopButton from './ScollToTopButton'


const StyledFooter = styled.footer`
  margin-top: auto;
  background-color: ${theme.colors.secondary};
  padding: 8.5rem 6rem 0 6rem;

  ${media.down('mobile')(css`
    padding: 0;
  `)}
`

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.down('mobile')(css`
    flex-direction: column;
    align-items: center;
    padding: 6rem 1.5rem 0;
  `)}
`

const StyledTypography = styled(Typography)`
  width: 70%;

  ${media.down('mobile')(css`
   width: 100%;
   text-align: center;
  `)}
`

const StyledButtonContainer = styled.div`
  position: relative;
  width: 100%;
  height: 5.3rem;
  overflow: hidden;

  ${media.down('mobile')(css`
    height: 9.675rem;
  `)}
`

const StyledCopyrightContainer = styled.div`
  ${media.down('mobile')(css`
    position: absolute;
    background-color:  ${theme.colors.dark};
    top: 5.3rem;
    height: 4.3rem;
    width: 100%;
    opacity: 1;
 `)}
`

const StyledCopyright = styled(Typography)`
  display: flex;
  position: absolute;
  top: 1.5rem;
  right: 0;
  opacity: 0.5;
  line-height: 1;
  color: ${theme.colors.textInverted}80;

  ${media.down('mobile')(css`
    justify-content: center;
    padding-top: 0.25rem;
    width: 100%;
 `)}
`

const Footer = ({message, pageUpText, copyrightText, children, ...props}) => {
  return (
    <StyledFooter {...props}>
      <StyledWrapper>
        <StyledTypography color="textInverted" type="h2">{message}</StyledTypography>
        {children}
      </StyledWrapper>
      <StyledButtonContainer>
        <ScrollToTopButton>{pageUpText}</ScrollToTopButton>
        <StyledCopyrightContainer>
          <StyledCopyright variant="span">{copyrightText}</StyledCopyright>
        </StyledCopyrightContainer>
      </StyledButtonContainer>
    </StyledFooter>
  )
}

Footer.propTypes = {
  message: PropTypes.string.isRequired,
  pageUpText: PropTypes.string.isRequired,
  copyrightText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Footer
