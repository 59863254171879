import PropTypes from 'prop-types'
import {For} from 'babel-plugin-jsx-control-statements'
import footerData from '../../../../content/settings/footer.json'
import Footer from '../../molecules/Footer'
import FooterNavigaton from '../../molecules/Footer/FooterNavigation'
import FooterNavigatonLink from '../../molecules/Footer/FooterNavigationLink'
import FooterContactsSection from '../../molecules/Footer/FooterContactsSection'
import FooterContactLink from '../../molecules/Footer/FooterContactLink'
import Seo from '../Seo'


const AppShell = ({title, lang, langToSlug, seo, children}) => {
  const footer = footerData[lang]

  return (
    <div id="backdropFilter">
      <Seo title={title} lang={lang} langToSlug={langToSlug} seo={seo} />
      {children}
      <Footer
          message={footer.message}
          pageUpText={footer.pageUpText}
          copyrightText={footer.copyright}
      >
        <FooterNavigaton>
          <For each="link" of={footer.links} index="idx">
            <FooterNavigatonLink key={idx} to={link.link} $small>{link.text}</FooterNavigatonLink>
          </For>
        </FooterNavigaton>
        <FooterContactsSection heading={footer.office.heading}>
          <For each="contact" of={footer.office.contacts} index="idx">
            <FooterContactLink key={idx} href={contact.link}>
              {contact.text}
            </FooterContactLink>
          </For>
        </FooterContactsSection>
      </Footer>
    </div>
  )
}

AppShell.propTypes = {
  title: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  langToSlug: PropTypes.object.isRequired,
  seo: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default AppShell
